import React from 'react';
import '../assets/About.css';

const Privacy = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>Dasar Privasi</h2>
            <p className='card-text'>
              Di <strong>HargaPetrol</strong>, privasi anda adalah keutamaan kami. Dasar Privasi
              ini menerangkan bagaimana kami mengumpul, menggunakan, dan melindungi maklumat
              peribadi anda apabila anda melawat dan berinteraksi dengan laman web kami.
              Dengan menggunakan laman web <strong>HargaPetrol</strong>, anda bersetuju dengan amalan yang dinyatakan dalam Dasar Privasi ini.
            </p>

            <h2 className='h4 font-weight-bold'>1. Maklumat yang Kami Kumpul</h2>
            <p className='card-text'>
              Kami mungkin mengumpul pelbagai jenis maklumat daripada pengguna laman web kami,
              termasuk:
            </p>
            <ul>
              <li>
                <strong>Maklumat Peribadi:</strong>
                Apabila anda menghantar maklumat melalui borang hubungan atau mendaftar untuk
                perkhidmatan kami, kami mungkin meminta maklumat peribadi seperti nama, alamat
                e-mel, dan nombor telefon anda.</li>
              <li>
                <strong>Maklumat Bukan Peribadi:</strong>
                Kami juga mengumpul maklumat bukan peribadi seperti jenis pelayar web yang
                digunakan, alamat IP, sistem operasi, dan data teknikal lain yang membantu kami
                memahami bagaimana laman web kami digunakan.</li>
            </ul>

            <h2 className='h4 font-weight-bold'>2. Bagaimana Kami Menggunakan Maklumat Anda</h2>
            <p className='card-text'>
              Maklumat yang kami kumpul digunakan untuk tujuan berikut:
            </p>
            <ul>
              <li>
                <strong>Memberi Perkhidmatan:</strong>
                Untuk memproses permintaan anda dan menyediakan perkhidmatan yang anda perlukan.</li>
              <li>
                <strong>Penambahbaikan Laman Web:</strong>
                Untuk memahami bagaimana pengguna berinteraksi dengan laman web kami dan
                memperbaiki pengalaman pengguna.</li>
              <li>
                <strong>Komunikasi:</strong>
                Untuk menghantar maklumat yang berkaitan dengan perkhidmatan kami, termasuk
                kemas kini atau maklumat promosi, jika anda telah bersetuju untuk menerimanya.</li>
              <li>
                <strong>Pemenuhan Undang-Undang:</strong>
                Untuk mematuhi undang-undang dan peraturan yang berkaitan.</li>
            </ul>

            <h2 className='h4 font-weight-bold'>3. Perlindungan Maklumat Anda</h2>
            <p className='card-text'>
              Kami mengambil langkah-langkah yang sewajarnya untuk melindungi maklumat
              peribadi anda daripada akses yang tidak sah, pendedahan, atau pengubahan. Ini
              termasuk penggunaan protokol keselamatan yang sesuai dan perlindungan teknikal
              serta fizikal.
            </p>

            <h2 className='h4 font-weight-bold'>4. Kuki (Cookies)</h2>
            <p className='card-text'>
              Laman web
              <strong>HargaPetrol</strong> menggunakan kuki untuk meningkatkan pengalaman pengguna. Kuki adalah fail kecil
              yang disimpan pada peranti anda yang membantu kami memahami bagaimana anda
              menggunakan laman web kami. Anda boleh mengawal penggunaan kuki melalui tetapan
              pelayar web anda, tetapi ini mungkin mempengaruhi fungsi laman web kami.
            </p>

            <h2 className='h4 font-weight-bold'>5. Pendedahan kepada Pihak Ketiga</h2>
            <p className='card-text'>
              Kami tidak menjual, memperdagangkan, atau memindahkan maklumat peribadi anda
              kepada pihak ketiga tanpa persetujuan anda, kecuali dalam keadaan berikut:
            </p>
            <ul>
              <li>
                <strong>Pematuhan Undang-Undang:</strong>
                Jika dikehendaki oleh undang-undang atau untuk melindungi hak, harta benda, atau
                keselamatan kami atau orang lain.</li>
              <li>
                <strong>Penyedia Perkhidmatan:</strong>
                Kami mungkin berkongsi maklumat anda dengan penyedia perkhidmatan pihak ketiga
                yang membantu kami dalam menjalankan operasi laman web kami, selagi pihak
                tersebut bersetuju untuk menyimpan maklumat ini dengan selamat.</li>
            </ul>

            <h2 className='h4 font-weight-bold'>6. Pautan ke Laman Web Pihak Ketiga</h2>
            <p className='card-text'>
              Laman web kami mungkin mengandungi pautan ke laman web pihak ketiga. Kami tidak
              bertanggungjawab terhadap dasar privasi atau kandungan laman web tersebut. Kami
              menggalakkan anda untuk menyemak dasar privasi setiap laman web yang anda
              lawati.
            </p>

            <h2 className='h4 font-weight-bold'>7. Hak Anda</h2>
            <p className='card-text'>
              Anda mempunyai hak untuk:
            </p>
            <ul>
              <li>
                <strong>Mengakses:</strong>
                Meminta salinan maklumat peribadi yang kami simpan tentang anda.</li>
              <li>
                <strong>Membetulkan:</strong>
                Meminta pembetulan terhadap sebarang maklumat peribadi yang tidak tepat.</li>
              <li>
                <strong>Memadam:</strong>
                Meminta pemadaman maklumat peribadi anda dalam keadaan tertentu.</li>
              <li>
                <strong>Menarik Balik Persetujuan:</strong>
                Menarik balik persetujuan anda terhadap penggunaan data anda, walaupun ini
                mungkin menjejaskan perkhidmatan yang kami sediakan kepada anda.</li>
            </ul>

            <h2 className='h4 font-weight-bold'>8. Perubahan kepada Dasar Privasi</h2>
            <p className='card-text'>
              Kami mungkin mengemaskini Dasar Privasi ini dari semasa ke semasa. Sebarang
              perubahan akan diterbitkan di laman web ini, dan kami menggalakkan anda untuk
              menyemak dasar ini secara berkala supaya anda sentiasa mengetahui bagaimana
              maklumat anda dilindungi.
            </p>

            <h2 className='h4 font-weight-bold'>9. Hubungi Kami</h2>
            <p className='card-text'>
              Jika anda mempunyai sebarang soalan atau kebimbangan mengenai Dasar Privasi ini,
              sila hubungi kami melalui maklumat yang disediakan di laman web kami.
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Privacy;
