import React from 'react';
import '../../assets/About.css';

const Terms = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>Terms of Use</h2>
            <p className='card-text'>
              Welcome to PetrolPrice. By accessing and using our website, you agree to comply
              with and be bound by the terms of use outlined below. These terms are designed
              to ensure that our website is used responsibly and in accordance with the law.
            </p>

            <h2 className='h4 font-weight-bold'>1. Acceptance of Terms</h2>
            <p className='card-text'>
              By using the PetrolPrice website, you automatically agree to abide by all the
              terms outlined in this document. If you do not agree with any part of these
              terms, please discontinue using this website immediately.
            </p>

            <h2 className='h4 font-weight-bold'>2. Website Usage</h2>
            <p className='card-text'>
              You are permitted to access and use this website for personal and non-commercial
              purposes only. Your use of this website must comply with all applicable laws and
              regulations. You are prohibited from:
            </p>

            <ul>
              <li>Using this website in any way that violates local, state, national, or
                international laws or regulations.
              </li>

              <li>Transmitting, sending, or posting any material that contains viruses,
                malware, or any other harmful code.
              </li>

              <li>Attempting to gain unauthorized access to any part of the website, the
                server on which the website is hosted, or any server, computer, or database
                connected to our website.
              </li>

            </ul>

            <h2 className='h4 font-weight-bold'>3. Accuracy of Information</h2>
            <p className='card-text'>
              While we strive to ensure that all information provided on the PetrolPrice
              website is accurate and up-to-date, we do not guarantee the accuracy,
              completeness, or availability of the information at all times. The fuel prices
              displayed are based on information provided by the Ministry of Finance Malaysia
              (MOF) and KPDNHEP and may change without notice. Users are advised to verify
              fuel prices through official sources before making related decisions.
            </p>

            <h2 className='h4 font-weight-bold'>4. Copyright and Intellectual Property</h2>
            <p className='card-text'>
              All content on this website, including but not limited to text, graphics, logos,
              icons, and images, is the copyright of PetrolPrice or the respective content
              owners. You are not permitted to use, reproduce, modify, or distribute any part
              of the website's content without our written consent.
            </p>

            <h2 className='h4 font-weight-bold'>5. Links to Third-Party Websites</h2>
            <p className='card-text'>
              The PetrolPrice website may contain links to third-party websites for your
              convenience. We do not control or take responsibility for the content, privacy
              policies, or practices of these third-party websites. Use of links to
              third-party websites is entirely at your own risk.
            </p>

            <h2 className='h4 font-weight-bold'>6. Disclaimer of Liability</h2>
            <p className='card-text'>
              This website is provided "as is" without any representations or warranties,
              express or implied. PetrolPrice will not be liable for any loss or damage
              arising from the use of this website or reliance on the information provided,
              including but not limited to any direct, indirect, incidental, or consequential
              losses.
            </p>

            <h2 className='h4 font-weight-bold'>7. Changes to Terms of Use</h2>
            <p className='card-text'>
              PetrolPrice reserves the right to modify these terms of use at any time without
              prior notice. Any changes will be published on this website, and your continued
              use of the website after such changes will constitute your acceptance of the
              modified terms.
            </p>

            <h2 className='h4 font-weight-bold'>8. Governing Law</h2>
            <p className='card-text'>
              These terms of use will be governed by and construed in accordance with the laws
              of Malaysia. Any disputes arising from or related to the use of this website
              will be resolved in the competent courts of Malaysia.
            </p>

            <h2 className='h4 font-weight-bold'>9. Contact Us</h2>
            <p className='card-text'>
              If you have any questions or concerns regarding these terms of use, please
              contact us using the information provided on our website.
            </p>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Terms;
