import React from 'react'
import { Chart } from 'react-google-charts'

export const options = {
  title: 'Prestasi Harga Petrol (RM)',
  curveType: 'function',
  legend: { position: 'bottom' },
  colors: ['#0AB04C', '#ffd100', '#595957', '#232323'],
}

export const optionsTable = {
  showRowNumber: true,
  legend: { position: 'bottom' }
}

const ChartPrice = ({ apiResponse }) => {
  const headerChart = [
    [
      'Date',
      'RON97',
      'RON95',
      'Diesel (Semenanjung)',
      'Diesel (Sabah, Sarawak & Labuan)'
    ]
  ]

  const headerTable = [
    [
      'Date',
      'RON97',
      'RON95',
      'Diesel (Semenanjung)',
      'Diesel (Sabah, Sarawak & Labuan)'
    ]
  ]

  const chartPrice = apiResponse.ChartFuelPrice.original;

  if (!chartPrice) {
    return <div>Loading Chart...</div>
  }

  const dataChart = chartPrice
  
  const data = [...headerChart, ...dataChart]
  
  const reversedDataChart = [...dataChart].reverse(); // Use spread to create a new array

  const dataTable = [...headerTable, ...reversedDataChart]
  
  return (
    <div className='row'>
      <div
        className='col-lg-12'
        style={{
          paddingTop: 25
        }}
      >
        <h2 className='h4 font-weight-bold'>
          Sejarah Harga Bahan Api Petrol dan Diesel
        </h2>
        <p className='card-text'>
          Maklumat dibawah adalah Sejarah harga bahan api Petrol dan Diesel bagi
          tempoh 3 bulan.
        </p>
      </div>
      <div
        className='col-lg-12 text-right'
        style={{
          paddingTop: 25
        }}
      >
        <div className='text-left'>
          <Chart
            chartType='LineChart'
            width='100%'
            height='100%'
            margin='0px'
            data={data}
            options={options}
          />
        </div>
      </div>
      <div
        className='col-lg-12'
        style={{
          paddingTop: 55
        }}
      >
        <div className='text-left'>
          <Chart
            chartType='Table'
            width='100%'
            height='100%'
            data={dataTable}
            options={options}
          />
        </div>
      </div>
      <div
        className='col-lg-12'
        style={{
          paddingTop: 25
        }}
      >
        <small className='d-block'>
          Kami menyediakan kemas kini harga petrol Malaysia mingguan terkini
          setiap hari Rabu jam 5.00 petang untuk RON95, RON97 dan Diesel di
          Malaysia. 'Bookmark' halaman kami sekarang!
        </small>
      </div>
    </div>
  )
}

export default ChartPrice
