import React from 'react';
import '../../assets/Footer.css';

const Footer = () => {
    return (
        <div className="row">
        <div className="col-lg-12">
          <div className="container mt-5">
            <footer className="bg-white border-top p-3 text-muted small">
              <div className="row align-items-center justify-content-between">
                <div>
                  <span className="navbar-brand mr-2">
                    <strong>PetrolPrice</strong>
                  </span>
                  Copyright © . All rights reserved.
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
};

export default Footer;
