import React, {useState, useEffect} from 'react';
import axios from 'axios';

function limitWords(str, n) {

  str = str.replace(/<\/?[^>]+(>|$)/g, ""); // removes all HTML tags

  // Split the string by spaces into an array of words
  const wordsArray = str.split(' ');

  // If the number of words is less than or equal to n, return the original string
  if (wordsArray.length <= n) {
    return str;
  }

  // Otherwise, slice the array to the first n words and join them back into a
  // string
  return wordsArray
    .slice(0, n)
    .join(' ') + '...';
}

const About = () => {

  const queryParameters = new URLSearchParams(window.location.search);
  var page = queryParameters.get("__page");

  if (!page) {
    page = 1;
  }

  const [apiResponse,
    getAllNews] = useState(null);

  useEffect(() => {
    axios
      .get('https://api.hargapetrol.com.my/api/petrol-news-all/en/' + page)
      .then(response => getAllNews(response.data))
      .catch(error => console.error('Error fetching news:', error));
  }, [page]); // Runs once when the component mounts

  if (!apiResponse) {
    return <div>Loading ...
      <img
        src="https://hargapetrol.com.my/assets/loading.gif"
        width="17px"
        alt="loading"/></div>
  }

  const news = apiResponse.data;

  const pagination = apiResponse.total_pages;
  
  return (
    <div className='row' style={{
      paddingTop: 25
    }}>
      <div className="col-lg-12">
        <h5 className="font-weight-bold spanborder">
          <span>News & Articles</span>
        </h5>
      </div>
      {news.map((article, index) => {
        // Parse and format the created_at date
        const dateObj = new Date(article.created_at);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const year = dateObj.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        return (
          <div className="col-lg-12" key={article.id || index}>
            <div className="flex-md-row mb-4 box-shadow">
              <div className="mb-3 d-flex row">
                <div className='col-lg-3'>
                  <a href={"/en/news/" + article.title}><img width="100%" src={article.picture + "500"} alt={article.title}/></a>
                </div>
                <div className="col-lg-9">
                  <h2 className="mb-2 h6 font-weight-bold">
                    <a className="text-dark" href={"/en/news/" + article.title}>{article.title}</a>
                  </h2>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                      __html: limitWords(article.content, 20)
                    }}/>
                  </p>
                  <small className="text-muted">
                    {formattedDate}
                    · {article.reading_time}&nbsp;
                    min read
                  </small>
                </div>
              </div>
            </div>
            <hr/>
          </div>
        );
      })}

      

    <div className="col-lg-12">
      <small className="text-muted">
        Page:&nbsp;&nbsp;&nbsp;
        {
          Array.from({ length: pagination }, (v, i) => (
            <span key={i}>
              {
                (i + 1 === page) ? (
                  <i style={{marginLeft:'20px'}}>{i + 1}&nbsp;&nbsp;</i>
                ) : (
                  <a href={"./articles?__page=" + (i + 1)} style={{marginLeft:'20px'}}>{i + 1}</a>
                )
              }
            </span>
          ))
        }
      </small>
    </div>


    </div>

  );
};

export default About;
