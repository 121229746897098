import React from 'react';
import '../../assets/About.css';

const About = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>About Us</h2>
            <p className='card-text'>
              Welcome to https://hargapetrol.com.my, your trusted source for the most accurate and up-to-date petrol and diesel prices in Malaysia. We understand how important it is to know fuel prices, whether you are a daily consumer, a business owner, or anyone looking to manage expenses effectively. Our mission is to provide you with the latest fuel prices, ensuring you have the information you need at any time.
            </p>
            <h2 className='h4 font-weight-bold'>Our Data Sources</h2>
            <p className='card-text'>
              The data displayed on https://hargapetrol.com.my is directly sourced from the Ministry of Finance Malaysia (MOF) and the Ministry of Domestic Trade and Cost of Living (KPDNHEP). These government agencies are responsible for setting and announcing fuel prices weekly, ensuring that the information provided is official and accurate.
            </p>
            <p className='card-text'>
              We are committed to ensuring that the prices displayed on our platform reflect the current rates as determined by these authorities. Our team continuously monitors official announcements and promptly updates our website to provide you with the most current information.
            </p>
            <h2 className='h4 font-weight-bold'>Our Mission</h2>
            <p className='card-text'>
              At https://hargapetrol.com.my, we are dedicated to:
            </p>
            <ul>
              <li>Providing Accurate Information: We prioritize the accuracy and timeliness of the fuel prices we display, ensuring that you can trust the information you receive.
              </li>
              <li>
                Promoting Transparency: By sourcing our data from official government channels, we help promote transparency and public awareness of fuel price setting in Malaysia.
              </li>
              <li>
                Supporting the Community: Whether you are planning a long trip or budgeting for the month, knowing the current fuel prices can help you make more informed decisions.
              </li>
            </ul>
            <h2 className='h4 font-weight-bold'>How It Works</h2>
            <p className='card-text'>
              Each week, the Malaysian government announces the official petrol and diesel prices for the upcoming week. These prices are typically set based on global oil prices and other economic factors. Once these prices are announced, our system automatically updates to reflect the new rates, ensuring that the information you see is always current.
            </p>
            <h2 className='h4 font-weight-bold'>Contact Us</h2>
            <p className='card-text'>
              We value your feedback and are here to assist with any questions you may have. If you have any inquiries or suggestions, please do not hesitate to contact us. Your feedback helps us improve our services and better meet the needs of our users.
            </p>
            <p>
              Email: shahrul95.dev@gmail.com
            </p>
            <p className='card-text'>
              Thank you for choosing https://hargapetrol.com.my as your trusted source for petrol and diesel prices in Malaysia. We are committed to providing you with the most accurate and up-to-date information.
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default About;
