import React, {useState, useEffect} from 'react';
// import axios from 'axios';

const Sidebar = () => {

  const [apiResponse,
    setNews] = useState(null);

  // State to store the email entered by the user
  const [email,
    setEmail] = useState('');

  // State to track the submission status
  const [isSubmitting,
    setIsSubmitting] = useState(false);

  // Fetch the last row of fuel prices from the Laravel API useEffect(() => {
  // axios     .get('https://api.hargapetrol.com.my/api/petrol-news/en')
  // .then(response => setNews(response.data))     .catch(error =>
  // console.error('Error fetching news:', error)); }, []); // Runs once when the
  // component mounts

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(today.getDate()).padStart(2, '0'); // Pad single digit day with 0
  const currentDate = `${year}${month}${day}`; // Correct string concatenation

  const currentURLWithoutPath = `${window.location.protocol}//${window.location.hostname}${window.location.port
    ? ':' + window.location.port
    : ''}`;

  useEffect(() => {
    fetch(currentURLWithoutPath + `/data/news-en-${currentDate}.json`) // Assuming your .json file is in the public directory
      .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Convert the response to JSON
    }).then(data => setNews(data)) // Set the fetched data to state
      .catch(error => console.error('Error fetching news:', error));
  }, [currentDate,currentURLWithoutPath]); // Include currentDate as a dependency

  // Function to handle the form submission
  const handleSubscribe = async() => {
    if (!email) {
      alert('Enter your email address');
      return;
    }

    setIsSubmitting(true); // Disable the button

    try {
      // Send the POST request to the API
      const response = await fetch('https://api.hargapetrol.com.my/api/subscribe-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email})
      });

      if (response.ok) {
        alert('Email successfully save. Thank you for your subscription!');
        setEmail(''); // Clear the input field
      } else {
        alert('Error, please try again.');
      }
    } catch (error) {
      alert('Error when saving an email.');
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  // Ensure hooks are always called in the same order
  if (!apiResponse) {
    return <div>Retrieving news
      <img
        src="https://hargapetrol.com.my/assets/loading.gif"
        width="17px"
        alt="loading"/></div>
  }

  return (
    <section>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='border p-3 bg-lightblue'>
            <div className='row justify-content-between'>
              <div className='col-md-12 mb-2 mb-md-0'>
                <h5 className='font-weight-bold secondfont'>Get current news</h5>
                Get the latest news straight to your inbox for FREE!<br/><br/>
              </div>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-12'>
                    <input type='text' className='form-control' placeholder='Enter your email address' value={email} onChange={(e) => setEmail(e.target.value)} // Update state with user input
                    />
                  </div>
                  <div className='col-md-12 mt-2'>
                    <button type='button' className='btn btn-success btn-block' onClick={handleSubscribe} // Handle the button click
                      disabled={isSubmitting} // Disable button if isSubmitting is true
                    >
                      {isSubmitting
                        ? 'Sending...'
                        : 'Subscribe'}
                    </button>
                  </div>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='row'>
                  {/* ads here */}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className='row' style={{
        paddingTop: 25
      }}>
        <div className="col-lg-12">
          <h5 className="font-weight-bold spanborder">
            <span>Tips and News</span>
          </h5>
        </div>
        {apiResponse.map((article, index) => {
          // Parse and format the created_at date
          const dateObj = new Date(article.created_at);
          const day = String(dateObj.getDate()).padStart(2, '0');
          const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
          const year = dateObj.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;

          // const parsedImg = JSON.parse(article.img); parsedImg.urls.thumb =
          // parsedImg.urls.thumb.replace("news-img", "thumbnails");

          return (
            <div className="col-lg-12" key={article.id || index}>
              <div className="flex-md-row mb-4 box-shadow">
                <div className="mb-3 d-flex row">
                  <div className='col-lg-5'>
                    {/* <img height={80} src={"https://api.hargapetrol.com.my/"+parsedImg.urls.thumb} alt={article.title}/> */}
                    <a href={"/en/news/"+article.title}><img width="100%" src={article.picture+"500"} alt={article.title}/></a>
                  </div>
                  <div className="col-lg-7">
                    <h2 className="mb-2 h6 font-weight-bold">
                      <a className="text-dark" href={"/en/news/" + article.title}>{article.title}</a>
                    </h2>
                    <small className="text-muted">
                      {formattedDate}
                      · {article.reading_time}
                      &nbsp;min read
                    </small>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

    </section>
  );
};

export default Sidebar;
