import React from 'react';
import '../assets/About.css';

const About = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>Tentang Kami</h2>
            <p className='card-text'>
              Selamat datang ke https://hargapetrol.com.my, sumber anda yang boleh dipercayai untuk
              mendapatkan harga petrol dan diesel yang paling tepat dan terkini di Malaysia.
              Kami memahami betapa pentingnya untuk mengetahui harga bahan api, sama ada anda
              seorang pengguna harian, pemilik perniagaan, atau sesiapa sahaja yang ingin
              menguruskan perbelanjaan dengan berkesan. Misi kami adalah untuk menyediakan
              anda dengan harga bahan api terkini, memastikan anda mempunyai maklumat yang
              diperlukan pada bila-bila masa.
            </p>
            <h2 className='h4 font-weight-bold'>Sumber Data Kami</h2>
            <p className='card-text'>
              Data yang dipaparkan di https://hargapetrol.com.my diperoleh terus dari Kementerian
              Kewangan Malaysia (MOF) dan Kementerian Perdagangan Dalam Negeri dan Kos Sara
              Hidup (KPDNHEP). Agensi-agensi kerajaan ini bertanggungjawab menetapkan dan
              mengumumkan harga bahan api setiap minggu, memastikan bahawa maklumat yang
              disediakan adalah rasmi dan tepat.
            </p>
            <p className='card-text'>
              Kami komited untuk memastikan bahawa harga yang dipaparkan di platform kami
              mencerminkan kadar semasa seperti yang ditentukan oleh pihak berkuasa ini.
              Pasukan kami sentiasa memantau pengumuman rasmi dan mengemas kini laman web kami
              dengan segera untuk memberikan anda maklumat terkini.
            </p>
            <h2 className='h4 font-weight-bold'>Misi Kami</h2>
            <p className='card-text'>
              Di https://hargapetrol.com.my, kami bertekad untuk:</p>
            <ul>
              <li>Menyediakan Maklumat yang Tepat: Kami mengutamakan ketepatan dan kecepatan
                harga bahan api yang kami paparkan, memastikan anda boleh mempercayai maklumat
                yang anda terima.
              </li>
              <li>
                Menggalakkan Ketelusan: Dengan mendapatkan data kami dari saluran rasmi
                kerajaan, kami membantu mempromosikan ketelusan dan kesedaran awam mengenai
                penetapan harga bahan api di Malaysia.
              </li>
              <li>
                Menyokong Komuniti: Sama ada anda merancang perjalanan jauh atau merangka bajet
                bulanan, mengetahui harga bahan api semasa dapat membantu anda membuat keputusan
                yang lebih bijak.
              </li>
            </ul>
            <h2 className='h4 font-weight-bold'>Bagaimana Ia Berfungsi
            </h2>
            <p className='card-text'>
              Setiap minggu, kerajaan Malaysia mengumumkan harga rasmi petrol dan diesel untuk
              minggu yang akan datang. Harga ini biasanya ditetapkan berdasarkan harga minyak
              global dan faktor ekonomi lain. Sebaik sahaja harga ini diumumkan, sistem kami
              akan dikemas kini secara automatik untuk mencerminkan kadar baru, memastikan
              bahawa maklumat yang anda lihat sentiasa terkini.</p>
            <h2 className='h4 font-weight-bold'>Hubungi Kami
            </h2>
            <p className='card-text'>
              Kami menghargai maklum balas anda dan sedia membantu dengan sebarang pertanyaan
              yang anda mungkin ada. Jika anda mempunyai sebarang pertanyaan atau cadangan,
              sila jangan teragak-agak untuk menghubungi kami. Maklum balas anda membantu kami
              untuk memperbaiki perkhidmatan kami dan memenuhi keperluan pengguna dengan lebih
              baik.</p>

            <p>
              Email: shahrul95.dev@gmail.com
            </p>

            <p className='card-text'>
              Terima kasih kerana memilih https://hargapetrol.com.my sebagai sumber anda yang
              dipercayai untuk harga petrol dan diesel di Malaysia. Kami komited untuk
              menyediakan anda dengan maklumat yang paling tepat dan terkini.</p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default About;
