import React from 'react';
import '../../assets/About.css';

const Privacy = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>Privacy Policy</h2>
            <p className='card-text'>
              At <strong>PetrolPrice</strong>, your privacy is our priority. This Privacy Policy
              explains how we collect, use, and protect your personal information when you visit
              and interact with our website. By using the <strong>PetrolPrice</strong> website, you agree to the practices described in this Privacy Policy.
            </p>

            <h2 className='h4 font-weight-bold'>1. Information We Collect</h2>
            <p className='card-text'>
              We may collect various types of information from users of our website, including:
            </p>
            <ul>
              <li>
                <strong>Personal Information:</strong>
                When you submit information through a contact form or register for our services,
                we may ask for personal details such as your name, email address, and phone number.
              </li>
              <li>
                <strong>Non-Personal Information:</strong>
                We also collect non-personal information such as the type of web browser used,
                IP address, operating system, and other technical data that help us understand
                how our website is used.
              </li>
            </ul>

            <h2 className='h4 font-weight-bold'>2. How We Use Your Information</h2>
            <p className='card-text'>
              The information we collect is used for the following purposes:
            </p>
            <ul>
              <li>
                <strong>Providing Services:</strong>
                To process your requests and provide the services you need.
              </li>
              <li>
                <strong>Website Improvement:</strong>
                To understand how users interact with our website and improve the user experience.
              </li>
              <li>
                <strong>Communication:</strong>
                To send you information related to our services, including updates or promotional
                materials, if you have agreed to receive them.
              </li>
              <li>
                <strong>Legal Compliance:</strong>
                To comply with applicable laws and regulations.
              </li>
            </ul>

            <h2 className='h4 font-weight-bold'>3. Protecting Your Information</h2>
            <p className='card-text'>
              We take reasonable steps to protect your personal information from unauthorized
              access, disclosure, or alteration. This includes the use of appropriate security
              protocols and technical as well as physical safeguards.
            </p>

            <h2 className='h4 font-weight-bold'>4. Cookies</h2>
            <p className='card-text'>
              The <strong>PetrolPrice</strong> website uses cookies to enhance the user experience. Cookies are small files
              stored on your device that help us understand how you use our website. You can
              control the use of cookies through your web browser settings, but this may affect
              the functionality of our website.
            </p>

            <h2 className='h4 font-weight-bold'>5. Disclosure to Third Parties</h2>
            <p className='card-text'>
              We do not sell, trade, or transfer your personal information to third parties
              without your consent, except in the following situations:
            </p>
            <ul>
              <li>
                <strong>Legal Compliance:</strong>
                If required by law or to protect our rights, property, or safety or that of others.
              </li>
              <li>
                <strong>Service Providers:</strong>
                We may share your information with third-party service providers who assist us in
                operating our website, as long as these parties agree to keep this information secure.
              </li>
            </ul>

            <h2 className='h4 font-weight-bold'>6. Links to Third-Party Websites</h2>
            <p className='card-text'>
              Our website may contain links to third-party websites. We are not responsible for
              the privacy policies or content of those websites. We encourage you to review the
              privacy policy of each website you visit.
            </p>

            <h2 className='h4 font-weight-bold'>7. Your Rights</h2>
            <p className='card-text'>
              You have the right to:
            </p>
            <ul>
              <li>
                <strong>Access:</strong>
                Request a copy of the personal information we hold about you.
              </li>
              <li>
                <strong>Correction:</strong>
                Request correction of any inaccurate personal information.
              </li>
              <li>
                <strong>Deletion:</strong>
                Request the deletion of your personal information under certain circumstances.
              </li>
              <li>
                <strong>Withdraw Consent:</strong>
                Withdraw your consent to the use of your data, although this may affect the
                services we provide to you.
              </li>
            </ul>

            <h2 className='h4 font-weight-bold'>8. Changes to the Privacy Policy</h2>
            <p className='card-text'>
              We may update this Privacy Policy from time to time. Any changes will be posted on
              this website, and we encourage you to review this policy periodically so that you
              are always aware of how your information is protected.
            </p>

            <h2 className='h4 font-weight-bold'>9. Contact Us</h2>
            <p className='card-text'>
              If you have any questions or concerns about this Privacy Policy, please contact us
              through the information provided on our website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
