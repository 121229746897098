import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, useLocation} from 'react-router-dom'; // Import useParams hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquareShareNodes,
} from '@fortawesome/free-solid-svg-icons'

const News = () => {
  const {title} = useParams(); // Get the title from the URL
  const [apiResponse,
    setNews] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (title) {
      // Only fetch if title is available
      axios
        .get(`https://api.hargapetrol.com.my/api/petrol-news-title/${title}`)
        .then((response) => setNews(response.data))
        .catch((error) => console.error('Error fetching fuel price:', error));
    }
  }, [title]); // Depend on title

  if (!apiResponse) {
    return <div>Loading... <img
    src="https://hargapetrol.com.my/assets/loading.gif"
    width="17px"
    alt="loading"/></div>;
  }

  // const parsedImg = JSON.parse(apiResponse.img);

  const paragraphs = apiResponse
    .content
    .replace(/\n/g, '<br/>');
    
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  const dateObj = new Date(apiResponse.created_at);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = dateObj.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>{apiResponse.title}</h2>

            <p>
              <small>{formattedDate}
                <br/>
                <a
                  href={"https://twitter.com/intent/tweet?text="+apiResponse.title+"  "+encodeURIComponent(currentUrl)}
                  target="_blank" rel="noreferrer">
                  X Twitter <FontAwesomeIcon icon={faSquareShareNodes} />
                </a>
              </small>
            </p>

            <p
              className='card-text text-center'
              style={{
              paddingTop: 25,
              paddingBottom: 35
            }}>
              {/* <img
                src={"https://api.hargapetrol.com.my/" + parsedImg.urls.thumb}
                alt={apiResponse.title}/> */}
              <img
                src={apiResponse.picture+"1000"}
                alt={apiResponse.title}/>
              <small>Free image from Pexels</small>
            </p>

            <p className='card-text paragraph'>
              <div
                dangerouslySetInnerHTML={{
                __html: paragraphs
              }}/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
