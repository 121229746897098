import React from 'react';
import '../assets/About.css';

const Terms = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h4 font-weight-bold'>Syarat Penggunaan</h2>
            <p className='card-text'>
            Selamat datang ke HargaPetrol. Dengan mengakses dan menggunakan laman web kami, anda bersetuju untuk mematuhi dan terikat dengan syarat-syarat penggunaan yang dinyatakan di bawah. Syarat-syarat ini bertujuan untuk memastikan penggunaan laman web kami dilakukan secara bertanggungjawab dan sesuai dengan undang-undang.
            </p>

            <h2 className='h4 font-weight-bold'>1. Penerimaan Syarat</h2>
            <p className='card-text'>
            Dengan menggunakan laman web HargaPetrol, anda secara automatik bersetuju untuk mematuhi semua syarat yang dinyatakan dalam dokumen ini. Jika anda tidak bersetuju dengan mana-mana bahagian daripada syarat-syarat ini, sila hentikan penggunaan laman web ini dengan segera.</p>

            <h2 className='h4 font-weight-bold'>2. Penggunaan Laman Web</h2>
            <p className='card-text'>
            Anda dibenarkan untuk mengakses dan menggunakan laman web ini untuk tujuan peribadi dan bukan komersial sahaja. Penggunaan laman web ini mesti mematuhi semua undang-undang dan peraturan yang berkaitan. Anda dilarang untuk:</p>

            <ul>
        <li>Menggunakan laman web ini dengan cara yang melanggar undang-undang atau peraturan tempatan, negeri, nasional, atau antarabangsa.
        </li>

        <li>Memindah, menghantar, atau menyiarkan sebarang bahan yang mengandungi virus, malware, atau sebarang kod berbahaya lain.
        </li>

        <li>Mencuba untuk mendapatkan akses tanpa kebenaran ke mana-mana bahagian laman web, pelayan di mana laman web ini disimpan, atau sebarang pelayan, komputer, atau pangkalan data yang disambungkan ke laman web kami.
        </li>

      </ul>

      <h2 className='h4 font-weight-bold'>3. Ketepatan Maklumat</h2>
            <p className='card-text'>
            Walaupun kami berusaha sedaya upaya untuk memastikan bahawa semua maklumat yang diberikan di laman web HargaPetrol adalah tepat dan terkini, kami tidak menjamin ketepatan, kesempurnaan, atau ketersediaan maklumat tersebut pada setiap masa. Harga bahan api yang dipaparkan adalah berdasarkan maklumat yang diberikan oleh Kementerian Kewangan Malaysia (MOF) dan KPDNHEP dan mungkin berubah tanpa notis. Pengguna adalah dinasihatkan untuk mengesahkan harga bahan api melalui sumber rasmi sebelum membuat keputusan berkaitan.</p>

            <h2 className='h4 font-weight-bold'>4. Hak Cipta dan Harta Intelek</h2>
            <p className='card-text'>
            Semua kandungan yang terdapat di laman web ini, termasuk tetapi tidak terhad kepada teks, grafik, logo, ikon, dan gambar adalah hak cipta HargaPetrol atau pemilik kandungan tersebut yang berkaitan. Anda tidak dibenarkan untuk mengguna, mengeluarkan semula, mengubahsuai, atau mengedarkan mana-mana bahagian kandungan laman web ini tanpa kebenaran bertulis daripada kami.</p>

            <h2 className='h4 font-weight-bold'>5. Pautan ke Laman Web Pihak Ketiga</h2>
            <p className='card-text'>
            Laman web HargaPetrol mungkin mengandungi pautan ke laman web pihak ketiga yang disediakan untuk kemudahan anda. Kami tidak mengawal atau bertanggungjawab terhadap kandungan, dasar privasi, atau amalan laman web pihak ketiga tersebut. Penggunaan pautan ke laman web pihak ketiga adalah sepenuhnya atas risiko anda sendiri.</p>

            <h2 className='h4 font-weight-bold'>6. Pengecualian Tanggungjawab</h2>
            <p className='card-text'>
            Laman web ini disediakan "seadanya" tanpa sebarang representasi atau jaminan, sama ada tersurat atau tersirat. HargaPetrol tidak akan bertanggungjawab ke atas sebarang kerugian atau kerosakan yang timbul daripada penggunaan laman web ini atau kebergantungan terhadap maklumat yang diberikan, termasuk tetapi tidak terhad kepada sebarang kerugian langsung, tidak langsung, sampingan, atau akibatnya.</p>

            <h2 className='h4 font-weight-bold'>7. Perubahan kepada Syarat Penggunaan</h2>
            <p className='card-text'>
            HargaPetrol berhak untuk mengubah suai syarat-syarat penggunaan ini pada bila-bila masa tanpa notis terlebih dahulu. Sebarang perubahan akan diterbitkan di laman web ini, dan penggunaan laman web selepas perubahan tersebut akan dianggap sebagai penerimaan anda terhadap syarat-syarat yang telah diubah suai.</p>

            <h2 className='h4 font-weight-bold'>8. Undang-Undang yang Mengawal</h2>
            <p className='card-text'>
            Syarat-syarat penggunaan ini akan ditadbir oleh dan ditafsirkan mengikut undang-undang Malaysia. Sebarang pertikaian yang timbul daripada atau berkaitan dengan penggunaan laman web ini akan diselesaikan di mahkamah Malaysia yang berwibawa.</p>

            <h2 className='h4 font-weight-bold'>9. Hubungi Kami</h2>
            <p className='card-text'>
            Jika anda mempunyai sebarang soalan atau kebimbangan mengenai syarat-syarat penggunaan ini, sila hubungi kami melalui maklumat yang disediakan di laman web kami.</p>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Terms;
