// import react from 'react'

const faq = () => {
  return (
    <div className='row'>
      <div
        className='col-lg-12'
        style={{
          paddingTop: 25
        }}
      >
        <h2 className='h4 font-weight-bold'>
          Everything You Need to Know About Fuel in Malaysia
        </h2>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Why Is Petrol in Malaysia So Cheap?
        </h5>
        <p className='card-text'>
          Malaysia is currently among the top 10 countries with the cheapest petrol in
          the world. The low price of RON95 petrol is due to subsidies provided by
          the government. Additionally, Malaysia is a major producer of oil and
          gas with significant reserves and a developed industry, resulting in relatively
          low production and distribution costs, leading to lower fuel prices.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Should I wait to pump my car if petrol prices drop next week?
        </h5>
        <p className='card-text'>
          If there is reliable information that petrol prices will drop next week,
          it might be a strategic decision to delay filling your car until the price drops.
          It could help you save a little in the short term.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          When is the best time to refill the gas tank?
        </h5>
        <p className='card-text'>
          While there are many theories out there suggesting that filling your
          car's fuel in the morning and late at night gets you more fuel, that's not true.
          Yes, fuel expands at higher temperatures, BUT the fuel at gas stations
          is buried underground and insulated, so you won't notice any significant
          difference in the amount of fuel you get.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Will fuel prices be affected by the rise of electric cars?
        </h5>
        <p className='card-text'>
          As there is a trend towards using more energy-efficient and cleaner electric cars,
          the demand for traditional fuel is expected to decrease. This could lead to a drop
          in petrol prices in the near future.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Does driving style affect fuel consumption?
        </h5>
        <p className='card-text'>
          A study by Irene Michelle Berry from the Massachusetts Institute of Technology found
          that minimizing acceleration and maintaining a lower speed on the highway can save fuel.
          Another study by Jeremy Clarkson and the Top Gear staff, where they tested two cars, a
          Toyota Prius and a BMW M3, also concluded that the more aggressively a driver accelerates,
          the more fuel will be burned.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Does tire pressure affect fuel consumption?
        </h5>
        <p className='card-text'>
          Correct! Proper air pressure levels in your tires and low tire rotation will require less
          energy for your car to use fuel.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Why Are Petrol Cars More Popular in Malaysia Compared to Diesel?
        </h5>
        <p className='card-text'>
          There are several reasons why petrol cars are more popular in Malaysia
          compared to diesel cars. One reason is that petrol cars are generally cheaper
          to buy than diesel cars in Malaysia due to the higher taxes imposed on
          diesel cars by the government, which generally encourages the use of petrol
          cars. As a result, many consumers choose petrol cars over diesel due to their
          more affordable prices.
          <br /><br/>
          Another reason is the perception among Malaysian consumers that petrol cars
          are more reliable and easier to maintain compared to diesel cars. It could be
          that petrol cars have historically been more popular in Malaysia, and therefore,
          there is more widespread support for petrol engines in terms of repair and
          maintenance services. Additionally, petrol cars are more suitable for city
          driving in Malaysia.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Diesel or Petrol Cars, Which is More Efficient?
        </h5>
        <p className='card-text'>
          Based on the fact that diesel produces more energy than petrol, it’s not wrong to say
          that it is more efficient. More energy means it generates more torque, which
          relates to higher mileage. Additionally, the price of diesel in Malaysia is lower
          than RON95, making it more cost-efficient! As for the type of fuel your car uses,
          whether it’s RON95, RON97, or Diesel, it all depends on the type of car you have.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Is Petrol Cleaner than Diesel?
        </h5>
        <p className='card-text'>
          Diesel engines emit less CO2 to cover the same distance as petrol engines because
          they operate at a higher compression ratio and are therefore more efficient than
          petrol engines.
          <br /><br/>
          However, since petrol is more refined than diesel,
          petrol engines emit fewer fine particles into the atmosphere.
          <br /><br/>
          As both modern diesel & petrol engines have advanced rapidly over the years, the
          debate about which one is cleaner remains inconclusive.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          What is the difference between RON 95 vs RON 97?
        </h5>
        <p className='card-text'>
          RON stands for Research Octane Number. The main difference between RON95
          and RON97 is their octane rating. RON95 has an octane rating of 95, while
          RON97 has an octane rating of 97.
          <br /><br/>
          The higher octane rating of RON97 means it has a greater resistance to knocking or
          engine knocking, which can occur when fuel ignites prematurely in the engine.
          This resistance to knocking allows engines to operate at a higher compression ratio,
          providing better performance and fuel efficiency.
          <br /><br/>
          However, not all engines require RON97 fuel. Engines with lower compression ratios,
          for example, can run on RON95 without any issues. On the other hand, some high-performance
          engines may require higher octane fuel like RON97 to prevent knocking or engine damage.
          <br /><br/>
          In short, RON97 provides better performance and fuel efficiency compared to RON95, but it comes
          at a higher price. Whether you should choose RON95 or RON97 ultimately depends on your engine’s
          needs and your preference for performance versus cost.
          <br />
          But for the average daily car, you won't see
          much real difference in performance between RON 95 & RON 97.
        </p>
      </div>
    </div>
  )
}

export default faq;
