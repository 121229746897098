// import react from 'react'

const faq = () => {
  return (
    <div className='row'>
      <div
        className='col-lg-12'
        style={{
          paddingTop: 25
        }}
      >
        <h2 className='h4 font-weight-bold'>
          Semua yang Anda Perlu Tahu Mengenai Bahan Api di Malaysia
        </h2>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Mengapa Petrol di Malaysia Sangat Murah?
        </h5>
        <p className='card-text'>
          Malaysia kini berada dalam 10 negara teratas dengan petrol termurah di
          dunia. Harga petrol RON95 murah kerana subsidi diberikan oleh
          kerajaan. Tambahan pula, Malaysia merupakan pengeluar utama minyak dan
          gas dengan rizab yang besar, dan industri yang maju, mengakibatkan kos
          pengeluaran dan pengedaran yang agak rendah, serta harga bahan api
          yang lebih rendah.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Perlukah saya menunggu untuk mengepam kereta saya jika harga petrol
          turun minggu depan?
        </h5>
        <p className='card-text'>
          Sekiranya terdapat maklumat yang boleh dipercayai bahawa harga petrol
          turun minggu depan, mungkin keputusan strategik untuk menangguhkan
          mengisi minyak kereta anda sehingga harga turun. Ia boleh membantu
          anda menjimatkan sedikit dalam jangka pendek.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Bilakah masa terbaik untuk mengisi semula tangki gas?
        </h5>
        <p className='card-text'>
          Walaupun banyak teori di luar sana mengatakan bahawa untuk mengisi
          minyak kereta anda pada waktu pagi dan larut malam untuk mendapatkan
          lebih banyak minyak, itu tidak benar. Ya, bahan api mengembang semasa
          suhu tinggi, TETAPI bahan api di stesen minyak tertimbus di bawah
          tanah dan terlindung, Oleh itu anda tidak akan merasakan sebarang
          perbezaan ketara dalam jumlah bahan api yang anda perolehi.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Adakah harga bahan api akan terjejas oleh kenaikan kereta elektrik?
        </h5>
        <p className='card-text'>
          Memandangkan terdapat kecenderungan dalam menggunakan kereta elektrik
          yang cekap tenaga dan lebih bersih, jangkaan permintaan untuk bahan
          api tradisional akan berkurangan. Ini boleh menyebabkan harga petrol
          turun dalam masa terdekat.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Adakah gaya pemanduan mempengaruhi penggunaan bahan api?
        </h5>
        <p className='card-text'>
          Kajian yang dibuat oleh Irene Michelle Berry dari Institut Teknologi
          Massachusetts menyatakan bahawa meminimumkan pecutan dan mengekalkan
          kelajuan yang rendah semasa lebuh raya boleh menjimatkan bahan api.
          Satu lagi kajian yang dibuat oleh Jeremy Clarkson dan kakitangan Top
          Gear di mana mereka menguji dua kereta iaitu Toyota Prius dan BMW M3
          juga menyimpulkan bahawa lebih agresif pemandu memecut, lebih banyak
          bahan api akan terbakar.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Adakah tekanan tayar menjejaskan penggunaan bahan api?
        </h5>
        <p className='card-text'>
          Betul! Tahap tekanan udara yang betul dalam tayar anda dan putaran
          tayar yang rendah akan memerlukan lebih sedikit tenaga untuk kereta
          anda menggunakan bahan api.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Mengapa Kereta Petrol Lebih Popular di Malaysia berbanding Diesel?
        </h5>
        <p className='card-text'>
          Terdapat beberapa sebab mengapa kereta petrol lebih popular di
          Malaysia berbanding kereta diesel. Salah satu sebabnya ialah kereta
          petrol secara umumnya lebih murah untuk dibeli berbanding kereta
          diesel di Malaysia berikutan cukai yang lebih tinggi yang dikenakan ke
          atas kereta diesel oleh kerajaan, yang secara amnya menggalakkan
          penggunaan kereta petrol. Akibatnya, ramai pengguna memilih kereta
          petrol berbanding diesel kerana harganya lebih berpatutan.
          <br /><br/>
          Sebab lain ialah persepsi pengguna di Malaysia bahawa kereta petrol
          lebih dipercayai dan mudah diselenggara berbanding kereta diesel. Ia
          mungkin bahawa kereta petrol lebih popular di Malaysia dari segi
          sejarah, dan oleh itu, terdapat sokongan yang lebih meluas untuk enjin
          petrol dari segi perkhidmatan pembaikan dan penyelenggaraan. Selain
          itu, kereta Petrol lebih sesuai untuk pemanduan bandar di Malaysia.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Kereta Diesel atau Petrol, yang mana lebih cekap?
        </h5>
        <p className='card-text'>
          Berdasarkan fakta bahawa diesel menghasilkan lebih banyak tenaga
          daripada petrol, maka tidak salah untuk mengatakan bahawa ia lebih
          cekap. Lebih banyak tenaga bermakna ia menghasilkan lebih tork sekali
          gus berkaitan dengan perbatuan yang lebih tinggi. Selain itu, harga
          diesel di Malaysia lebih rendah daripada RON95 menjadikannya lebih
          cekap dari segi kos! Mengenai jenis minyak kereta anda, sama ada
          menggunakan RON95, RON97, atau Diesel, semuanya bergantung kepada
          jenis kereta yang anda gunakan.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Adakah Petrol Lebih Bersih daripada Diesel?
        </h5>
        <p className='card-text'>
          Enjin diesel mengeluarkan kurang CO2 untuk meliputi kilometer yang
          sama daripada enjin petrol kerana ia berfungsi dalam nisbah mampatan
          yang lebih tinggi dan oleh itu lebih cekap daripada enjin petrol.
          <br /><br/>
          Walaubagaimanapun, memandangkan petrol lebih halus daripada diesel,
          enjin petrol mengeluarkan lebih sedikit zarah halus ke atmosfera.
          <br /><br/>
          Memandangkan kedua-dua enjin diesel & petrol moden telah meningkat
          dengan pesat selama bertahun-tahun, perdebatan tentang siapa yang
          lebih bersih tidak dapat dipastikan.
        </p>

        <h5
          className='h5 font-weight-bold'
          style={{
            paddingTop: 25
          }}
        >
          Apakah perbezaan antara RON 95 vs RON 97?
        </h5>
        <p className='card-text'>
          RON bermaksud Research Octane Number. Perbezaan utama antara RON95
          dan RON97 ialah penarafan oktana (Octane) mereka. RON95 mempunyai penarafan
          oktana 95, manakala RON97 mempunyai penarafan oktana 97.
          <br /><br/>
          Penarafan oktana RON97 yang lebih tinggi bermakna ia mempunyai
          rintangan yang lebih tinggi terhadap ketukan (knocking) atau pembakaran enjin dan
          boleh berlaku apabila bahan api menyala sebelum waktunya dalam enjin.
          Rintangan terhadap ketukan ini membolehkan enjin beroperasi pada
          nisbah mampatan yang lebih tinggi, memberikan prestasi yang lebih baik
          dan kecekapan bahan api.
          <br /><br/>
          Namun, tidak semua enjin memerlukan bahan api RON97. Enjin dengan
          nisbah mampatan yang lebih rendah sebagai contoh enjin yang boleh berjalan pada
          RON95 tanpa sebarang masalah. Sebaliknya, sesetengah enjin berprestasi
          tinggi mungkin memerlukan bahan api oktana yang lebih tinggi seperti
          RON97 untuk mengelakkan ketukan atau kerosakan enjin.
          <br /><br/>
          Secara ringkasnya, RON97 memberikan prestasi dan kecekapan bahan api
          yang lebih baik berbanding RON95, tetapi ia datang pada harga yang
          lebih tinggi. Sama ada anda harus memilih RON95 atau RON97 akhirnya
          bergantung pada keperluan enjin anda dan keutamaan anda untuk prestasi
          berbanding kos.
          <br />
          Tetapi untuk purata kereta harian, anda tidak akan melihat
          banyak perbezaan sebenar dalam prestasi antara RON 95 & RON 97.
        </p>
      </div>
    </div>
  )
}

export default faq
